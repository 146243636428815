import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export const getMinDate = (): NgbDateStruct => {
  const currentDate = new Date();
  return {
    year: currentDate.getFullYear() - 100,
    month: currentDate.getMonth() + 1,
    day: currentDate.getDate(),
  };
};

export const getMaxDate = (): NgbDateStruct => {
  const currentDate = new Date();
  return {
    year: currentDate.getFullYear(),
    month: currentDate.getMonth() + 1,
    day: currentDate.getDate(),
  };
};
