import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertType, DateHelper } from '@isophi/core-legacy';
import { birthDateExample } from '@isophi/core-ui';
import { NgbActiveModal, NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { Child, Contract, Kindergarten } from '@isophi/parents-api';
import { NgxSpinnerService } from 'ngx-spinner';
import { v4 as uuid } from 'uuid';
import { RouterLinkFactory } from '../../../../core/router/router-link.factory';
import { ChildService } from '../../../../core/services/child.service';
import { ContractService } from '../../../../core/services/contract.service';
import { printableFullText } from '../../../../core/utils/contract.utils';
import { PopUpComponent } from '../../../../shared/components/pop-up/pop-up.component';
import { getMaxDate, getMinDate } from '../../../../core/utils/date.utils';

enum ActionType {
  MANUAL = 'manual',
  BY_CODE = 'by_code',
}

@Component({
  selector: 'app-add-child',
  templateUrl: './add-child.component.html',
  styleUrls: ['./add-child.component.scss'],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AddChildComponent implements OnInit {
  @ViewChild('manualFormElement') public manualForm: NgForm;

  @ViewChild('byCodeFormElement') public byCodeForm: NgForm;

  @Input() public actionType: ActionType = ActionType.MANUAL;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public ActionType: typeof ActionType = ActionType;

  public editedChild: Child = null;

  public childCode = '';

  public errors: Array<string> = [];

  public kindergarten: Kindergarten | null = null;

  public userKindergartenSharingContract: Contract | null = null;

  public childCodeRegistrationContract: Contract | null = null;

  public childManualRegistrationContract: Contract | null = null;

  public childCodeRegistrationConsent = false;

  public childManualRegistrationConsent = false;

  public birthDateExample: string;

  public data: any;

  minDate = getMinDate();
  maxDate = getMaxDate();

  public constructor(
    public activeModal: NgbActiveModal,
    public routerLinkFactory: RouterLinkFactory,
    private popUp: PopUpComponent,
    private childService: ChildService,
    private spinner: NgxSpinnerService,
    private contractService: ContractService,
    private router: Router
  ) {
    this.contractService.getContract('user-kindergarten-sharing').subscribe((contract) => {
      this.userKindergartenSharingContract = contract;
    });
    this.contractService.getContract('child-code-registration').subscribe((contract) => {
      this.childCodeRegistrationContract = contract;
    });
    this.contractService.getContract('child-manual-registration').subscribe((contract) => {
      this.childManualRegistrationContract = contract;
    });

    // TODO: (js) catch: this.popUp.alert('Přidání dítěte', 'Přidání dítěte je nyní nedostupné, zkuste to prosím později.', AlertType.WARNING);

    this.birthDateExample = birthDateExample();
  }

  public get activeForm(): NgForm {
    return this.actionType === ActionType.MANUAL ? this.manualForm : this.byCodeForm;
  }

  public ngOnInit(): void {
    this.editedChild = {} as Child;
    this.editedChild.lastName = '(Nevyplněno)'; // Surname is now hidden for parents.
  }

  public childCodeChanged(): void {
    this.kindergarten = null;
  }

  /**
   * Activate action type and form by user select (MANUAL/by_code).
   *
   * @param actionType
   * @param event
   */
  public selectActionType(actionType: ActionType, event: Event): void {
    event.preventDefault();
    this.clearErrors();

    this.actionType = actionType;
  }

  /**
   * Process active form.
   */
  public processActiveForm(): void {
    this.spinner.show();
    this.clearErrors();

    if (this.actionType === ActionType.BY_CODE) {
      this.processByCodeForm();
    } else if (this.actionType === ActionType.MANUAL) {
      this.processManualForm();
    } else {
      throw new Error(`Unknown action type "${this.actionType}"`);
    }
  }

  public printableFullText(contract: Contract) {
    const map = new Map().set('kindergarten', this.kindergartenPrintableId());
    return printableFullText(contract, map);
  }

  /**
   * Add child manually - by child data.
   */
  protected processManualForm(): void {
    this.editedChild.uuid = uuid();
    this.editedChild.birthDate = DateHelper.toStringDateOnly(DateHelper.parse(this.editedChild.birthDate));

    this.childService.createChild(this.editedChild, this.childManualRegistrationContract, this.childManualRegistrationConsent).subscribe({
      next: (child) => {
        this.childService.reloadChildren();
        this.activeModal.close();
        this.spinner.hide();
        this.popUp.alert('Ruční přidání dítěte.', 'Dítě bylo úspěšně přidáno.', AlertType.SUCCESS);
        this.router.navigate(this.routerLinkFactory.dashboard(child));
        document.body.scroll(0, 0);
      },
      error: (reason) => {
        this.spinner.hide();
        const errorData = reason.error;

        if (errorData.code !== 1001) {
          this.popUp.alert('Ruční přidání dítěte.', 'Dítě se nepodařilo přidat. Zkuste to prosím později.', AlertType.WARNING);
          return;
        }

        this.childService.mapChildApiErrors(errorData.errors, this.errors);
      },
    });
  }

  /**
   * Add child by kindergarten code.
   */
  protected processByCodeForm(): void {
    if (this.kindergarten === null) {
      this.processByCodeFormLoadKindergarten();
    } else {
      this.processByCodeFormCopyChild();
    }
  }

  protected processByCodeFormLoadKindergarten(): void {
    this.childService.getKindergartenByChildCode(this.childCode).subscribe({
      next: (kindergarten) => {
        this.kindergarten = kindergarten;
        this.spinner.hide();
        document.body.scroll(0, 0);
      },
      error: (reason) => {
        this.spinner.hide();
        const errorData = reason.error;

        if (errorData.code !== 1001) {
          this.popUp.alert('Automatické přidání dítěte', 'Nelze ověřit kód dítěte, zkuste to prosím později.', AlertType.WARNING);
          return;
        }

        this.childService.mapChildApiErrors(errorData.errors, this.errors);
      },
    });
  }

  protected processByCodeFormCopyChild(): void {
    this.childService
      .addChildByCode(
        this.childCode,
        this.childCodeRegistrationContract,
        this.childCodeRegistrationConsent,
        this.userKindergartenSharingContract,
        this.kindergarten
      )
      .subscribe({
        next: (child) => {
          this.childService.reloadChildren();
          this.activeModal.close();
          this.spinner.hide();
          this.popUp.alert(
            'Automatické přidání dítěte.',
            'Gratulujeme, došlo ke spárování Vašeho profilu s mateřskou školou, kterou navštěvuje Vaše dítě.',
            AlertType.SUCCESS
          );
          this.router.navigate(this.routerLinkFactory.dashboard(child));
          document.body.scroll(0, 0);
        },
        error: (reason) => {
          this.spinner.hide();
          const errorData = reason.error;
          if (errorData.code !== 1001) {
            this.popUp.alert('Automatické přidání dítěte.', 'Dítě se nepodařilo přidat. Zkuste to později.', AlertType.WARNING);
            return;
          }
          this.childService.mapChildApiErrors(errorData.errors, this.errors);
        },
      });
  }

  /**
   * Return string unique identified one kindergarten, which is printable for user.
   */
  protected kindergartenPrintableId(): string {
    if (this.kindergarten.lawId) {
      return `${this.kindergarten.name}, IČO: ${this.kindergarten.lawId}`;
    } else {
      return this.kindergarten.name;
    }
  }

  private clearErrors() {
    this.errors.length = 0;
  }
}
